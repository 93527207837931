<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="error"
        class="text-pre-wrap"
        v-if="alertDanger.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertDanger"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="refundEmailSubmissionSubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="this.hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Draft this Refund Email for next
          approval.
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailSubmissionSubmit()"
          >
            Draft
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailSubmissionSubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="hrdcData.isTaxpodAdminRoles && !btnDisabled && !editMode">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailSubmissionSubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit Refund Email Draft</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <!-- taxPOD Price -->
          <v-col cols="5" style="border-right: 1px solid lightgrey">
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                disabled
                label="taxPOD Price"
                v-model="formattedPrice"
                :error-messages="errorField.taxpodPrice"
              ></v-text-field>
            </v-col>

            <!-- Subscription Start & End Date -->
            <v-row class="pl-3 align-items-center justify-space-between">
              <v-col cols="12" lg="5" sm="5" md="5">
                <v-menu
                  ref="menuSubscriptionStartDate"
                  v-model="menuSubscriptionStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="formData.subscriptionStartDate"
                      :error-messages="errorField.subscriptionStartDate"
                      label="Subscription Start Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.subscriptionStartDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menuSubscriptionStartDate = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuSubscriptionStartDate.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              to
              <v-col cols="12" lg="6" sm="6" md="6">
                <v-menu
                  ref="menuSubscriptionEndDate"
                  v-model="menuSubscriptionEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="formData.subscriptionEndDate"
                      :error-messages="errorField.subscriptionEndDate"
                      label="Subscription End Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.subscriptionEndDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menuSubscriptionEndDate = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuSubscriptionEndDate.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- Email Subject -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                v-model="formData.emailSubject"
                :error-messages="errorField.emailSubject"
                label="Email Subject"
              ></v-text-field>
            </v-col>

            <!--To taxPOD Approval Admin 1 & 2-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-combobox
                dense
                filled
                label="TO Recipient"
                v-model="formData.toEmail"
                :error-messages="errorField.toEmail"
                :hide-selected="true"
                multiple
                small-chips
                :readonly="editMode"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                    <v-icon small @click="data.parent.selectItem(data.item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>

                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-information </v-icon>
                    </template>
                    Press <kbd>enter/tab</kbd> to insert multiple email
                  </v-tooltip>
                </template></v-combobox
              >
            </v-col>

            <!--CC Related PIC (Top Management)-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-combobox
                dense
                filled
                label="CC Recipient"
                v-model="formData.ccEmail"
                :error-messages="errorField.ccEmail"
                :hide-selected="true"
                multiple
                small-chips
                :readonly="editMode"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                    <v-icon small @click="data.parent.selectItem(data.item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>

                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-information </v-icon>
                    </template>
                    Press <kbd>enter/tab</kbd> to insert multiple email
                  </v-tooltip>
                </template></v-combobox
              >
            </v-col>

            <!--Screenshot Refund Bank Account Details-->
            <v-col class="d-flex pb-0" cols="12" lg="12" sm="12" md="12">
              <div class="w-100">
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  small-chips
                  disabled
                  label="Screenshot Refund Bank Account Details"
                  v-model="formData.ssRefundBankAccDetails"
                  :truncate-length="1000"
                ></v-file-input>
              </div>
            </v-col>

            <!-- Include Screenshot checkbox -->
            <v-col class="d-flex pb-0 pt-0" cols="12">
              <div class="pt-0">
                <v-checkbox
                  class="pt-0"
                  color="primary"
                  @change="changeAttachSs()"
                  :label="this.computedSsCheckMsg"
                  v-model="formData.ssCheck"
                ></v-checkbox>
              </div>
            </v-col>

            <!--HRDC Invoice-->
            <v-row class="pl-3">
              <v-col class="d-flex" cols="12" lg="8" sm="8" md="8">
                <div class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="HRDC Invoice"
                    disabled
                    v-model="formData.manualInvoice"
                    :error-messages="errorField.manualInvoice"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" lg="4" sm="4" md="4" class="pl-0">
                <v-text-field
                  dense
                  filled
                  v-model="formData.manualInvoiceToHrdc"
                  :error-messages="errorField.manualInvoiceToHrdc"
                  label="HRDC Invoice No."
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <!--Client/taxPOD Invoice-->
            <v-row class="pl-3">
              <v-col class="d-flex" cols="12" lg="8" sm="8" md="8">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropMultipleFile(
                      $event,
                      'autoGeneratedTaxpodInvoice',
                      'autoGeneratedTaxpodInvoiceNo',
                      'pdf.*'
                    )
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    multiple
                    label="Client/taxPOD Invoice"
                    accept=".pdf"
                    @change="
                      scanPdf(
                        $event,
                        'autoGeneratedTaxpodInvoice',
                        'autoGeneratedTaxpodInvoiceNo'
                      )
                    "
                    v-model="formData.autoGeneratedTaxpodInvoice"
                    :error-messages="errorField.autoGeneratedTaxpodInvoice"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" lg="4" sm="4" md="4" class="pl-0">
                <v-text-field
                  dense
                  filled
                  v-model="formData.autoGeneratedTaxpodInvoiceNo"
                  :error-messages="errorField.autoGeneratedTaxpodInvoiceNo"
                  label="Invoice No."
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <!--Credit Note-->
            <v-row class="pl-3" cols="12">
              <v-col class="d-flex" lg="8" sm="8" md="8">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropMultipleFile(
                      $event,
                      'creditNote',
                      'creditNoteNo',
                      'pdf.*'
                    )
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    multiple
                    label="Credit Note"
                    @change="scanPdf($event, 'creditNote', 'creditNoteNo')"
                    v-model="formData.creditNote"
                    :error-messages="errorField.creditNote"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col lg="4" sm="4" md="4" class="pl-0">
                <v-text-field
                  dense
                  filled
                  v-model="formData.creditNoteNo"
                  :error-messages="errorField.creditNoteNo"
                  label="CN No."
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <!--Payment Advice From HRDC-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <div
                v-cloak
                @drop.prevent="
                  addDropFile($event, 'paymentAdviceFromHrdc', null, 'image/*')
                "
                @dragover.prevent
                class="w-100"
              >
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  small-chips
                  @change="checkAttachment($event, 'paymentAdviceFromHrdc')"
                  accept="image/*"
                  label="Payment Advice From HRDC"
                  v-model="formData.paymentAdviceFromHrdc"
                  :error-messages="errorField.paymentAdviceFromHrdc"
                  :truncate-length="1000"
                ></v-file-input>
              </div>
            </v-col>

            <!--Payment Advice From Client (Stripe/iPay/...)-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <div
                v-cloak
                @drop.prevent="
                  addDropFile($event, 'paymentAdviceFromClient', null, null)
                "
                @dragover.prevent
                class="w-100"
              >
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  small-chips
                  @change="checkAttachment($event, 'paymentAdviceFromClient')"
                  label="Payment Advice From Client"
                  v-model="formData.paymentAdviceFromClient"
                  :error-messages="errorField.paymentAdviceFromClient"
                  :truncate-length="1000"
                ></v-file-input>
              </div>
            </v-col>

            <!--Payment Advice Type-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-select
                :items="paymentAdviceItems"
                v-model="formData.paymentAdviceType"
                filled
                dense
                label="Payment Advice Type"
              ></v-select>
            </v-col>

            <!--Other Attachments-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <div
                v-cloak
                @drop.prevent="
                  addDropFile($event, 'refundOtherAttachments', null, null)
                "
                @dragover.prevent
                class="w-100"
              >
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  multiple
                  small-chips
                  @change="checkAttachment($event, 'refundOtherAttachments')"
                  label="Other Attachments (etc: Authorization Letter...)"
                  v-model="formData.refundOtherAttachments"
                  :error-messages="errorField.refundOtherAttachments"
                  :truncate-length="1000"
                  hint="Kindly add other attachments details to email template at below if have any."
                  persistent-hint
                ></v-file-input>
              </div>
            </v-col>
          </v-col>

          <v-col cols="7">
            <!-- Email Content -->
            <v-col cols="12" lg="12" sm="12" md="12">
              <div>
                <span class="text--disabled">Email Content</span>
                <span class="font-weight-bold text-danger"
                  >(*Only edit template after upload all attachments)</span
                >
              </div>

              <div>
                <!-- Use the component in the right place of the template -->
                <tiptap-vuetify
                  v-model="formData.emailContent"
                  :extensions="extensions"
                />
              </div>
              <span class="text-danger">{{
                errorField.emailContent ? errorField.emailContent[0] : null
              }}</span>
            </v-col>

            <!--Refund Remarks-->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-textarea
                v-model="formData.refundRemarks"
                filled
                dense
                no-resize
                label="Refund Remarks"
              ></v-textarea>
            </v-col>
          </v-col>
        </v-row>

        <!-- Refund Email Date -->
        <v-col cols="12" sm="12" md="6" v-show="false">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.refundEmailSubmissionDate"
                label="Refund Email Date (YYYY-MM-DD)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.refundEmailSubmissionDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc/objects/globalData";
import hrdcFunction from "@/services/hrdc/objects/hrdcFunction";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  mixins: [hrdcFunction],
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedPrice() {
      return `RM ${parseFloat(this.formData.taxpodPrice).toLocaleString()}`;
    },
    computedSsCheckMsg() {
      if (this.formData.ssCheck) {
        return "Include Screenshot Refund Bank Account Details in Refund Email";
      } else {
        return "Exclude Screenshot Refund Bank Account Details Refund Email";
      }
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiGetRefundAttachment: new Api(),
    apiGetRefundEmailReceiver: new Api(),
    apiScanPdfText: new Api(),
    apiSubmit: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    alertDanger: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menuSubscriptionStartDate: false,
    menuSubscriptionEndDate: false,
    formData: {
      applicationNo: null,
      taxpodPrice: null,
      manualInvoiceToHrdc: null,
      subscriptionStartDate: null,
      subscriptionEndDate: null,
      refundEmailSubmissionDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      autoGeneratedTaxpodInvoice: [],
      autoGeneratedTaxpodInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: "Stripe",
      emailContent: null,
      creditNote: [],
      creditNoteNo: null,
      previewCreditNote: null,
      toEmail: [],
      ccEmail: [],
      toEmailUuid: [],
      ccEmailUuid: [],
      manualInvoice: {
        name: "HRDC Invoice",
      },
      ssRefundBankAccDetails: {
        name: "Screenshot of Refund Bank Account Details",
      },
      refundRemarks: null,
      refundOtherAttachments: [],
      paymentReceivedDate: null,
      oldCompanyName: null,
      oldSsmNo: null,
      bankUuid: null,
      oldBankAccount: null,
      ssCheck: true,
      ssCheckMsg:
        "Include Screenshot Refund Bank Account Details in Refund Email",
      emailSubject: null,
      oriCompanyName: null,
      oldBankAccountHolderName: null,
    },
    errorField: {
      applicationNo: null,
      taxpodPrice: null,
      subscriptionStartDate: null,
      subscriptionEndDate: null,
      refundEmailSubmissionDate: null,
      autoGeneratedTaxpodInvoice: null,
      autoGeneratedTaxpodInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: null,
      creditNote: null,
      creditNoteNo: null,
      toEmail: null,
      ccEmail: null,
      refundOtherAttachments: null,
    },
    loadingDialog: false,
    refundEmailSubmissionSubmitDialog: false,
    editMode: false,
    btnDisabled: false,
    paymentAdviceItems: null,
    toEmailItems: [],
    ccEmailItems: [],
    numberSixItemWord:
      "Screenshot of Refund Bank Account Details Provided by Client",
    creditNoteValidationCheck: true,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  watch: {
    "formData.autoGeneratedTaxpodInvoiceNo": function () {
      this.updateEmailContent();
    },
    "formData.paymentAdviceType": function () {
      this.updateEmailContent();
    },
    "formData.creditNoteNo": function () {
      this.updateEmailContent();
    },
    "formData.subscriptionStartDate": function () {
      this.updateEmailContent();
    },
    "formData.subscriptionEndDate": function () {
      this.updateEmailContent();
    },
    "formData.ssCheck": function () {
      this.updateEmailContent();
    },
  },
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign payment advice options.
    this.paymentAdviceItems = this.hrdcData.paymentAdviceOpts;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/create-refund-email-submission`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiGetRefundEmailReceiver: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/get-refund-email-receiver`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/scan-pdf`,
        method: "post",
      },
      apiGetRefundAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-array`,
        method: "post",
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId];
    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    if (currentStageId >= this.hrdcData.stageSlug.refundEmailSubmission) {
      this.alertError.push("You already submitted this form");
      this.editMode = true;
    }

    if (currentStageId == 1900) {
      if (currentStageId < this.hrdcData.stageSlug.creditNoteDocumented) {
        this.alertError.push("Please complete previous step");
        this.btnDisabled = true;
      }
    } else if (currentStageId == 1850) {
      if (currentStageId < this.hrdcData.stageSlug.creditNoteGenerated) {
        this.alertError.push("Please complete previous step");
        this.btnDisabled = true;
      }
    }

    // If edit form to resend email submission.
    if (this.editMode) {
      this.apiGetApplicationSingleDetail.fetch();
      this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
        try {
          const { status, data, company } = response;
          if (status) {
            // Pricing
            this.formData.taxpodPrice = data.totalPrice;

            // Check if pricing is unsual, display warning.
            if (
              !this.hrdcData.staticTaxpodPricing.includes(
                Number(data.totalPrice)
              )
            ) {
              this.alertDanger.push("Detected Unusual Price, Please Take Note");
            }

            // Define related refund stages.
            const refundStage = [
              this.hrdcData.stagePriority.refundEmailSubmission,
              this.hrdcData.stagePriority.refundEmailApproval1,
              this.hrdcData.stagePriority.refundEmailApproval2,
            ];

            const subscription = data.CompanyTaxpodSubscription.find(
              (item) => item.applicationUuid == this.formData.applicationNo
            );

            // Subscription Period.
            this.formData.subscriptionStartDate =
              subscription !== undefined
                ? subscription.subscriptionStartDate
                : null;
            this.formData.subscriptionEndDate =
              subscription !== undefined
                ? subscription.subscriptionEndDate
                : null;

            // Recipient Details.
            let toRecipient, ccRecipient;
            let refundEmail = data.ApplicationEmail.find((item) =>
              refundStage.includes(item.stageId)
            );
            if (refundEmail) {
              toRecipient = refundEmail.ApplicationEmailDetail.filter(
                (item) => item.recipientType == "TO"
              ).map((item2) => item2.recipientEmail);
              ccRecipient = refundEmail.ApplicationEmailDetail.filter(
                (item) => item.recipientType == "CC"
              ).map((item2) => item2.recipientEmail);

              // Email subject.
              this.formData.emailSubject = refundEmail.emailSubject;
            }

            this.formData.toEmail = toRecipient;
            this.formData.ccEmail = ccRecipient;

            // HRDC Invoice No.
            this.formData.manualInvoiceToHrdc = data.manualInvoiceToHrdc;

            // Client/taxPOD Invoice No.
            this.formData.autoGeneratedTaxpodInvoiceNo =
              data.autoGeneratedTaxpodInvoiceNo;

            // CN No.
            this.formData.creditNoteNo = data.creditNoteNo;

            // Payment Advice Type.
            this.formData.paymentAdviceType = data.paymentAdviceFromClientType;

            // Assign company data.
            this.formData.oldCompanyName = this.capitalizeFirstLetter(
              company.oldCompanyName
            );
            this.formData.oldSsmNo = company.oldSsmNo;
            this.formData.bankUuid = company.bankUuid;
            this.formData.oldBankAccount = company.oldBankAccount;

            const refundEmailSubmissionStageId =
              this.hrdcData.stagePriority.refundEmailSubmission;
            this.formData.refundEmailSubmissionDate = moment(
              data.ApplicationLog.find(
                (obj) => obj.stageId == refundEmailSubmissionStageId
              ).startDate
            ).format("YYYY-MM-DD");

            // Email Content
            let refundEmailTemplate = data.ApplicationEmail.find((item) =>
              refundStage.includes(item.stageId)
            );

            if (refundEmailTemplate) {
              refundEmailTemplate = refundEmailTemplate.emailContent;
            }

            // Check is drafted refund email template got keyword 'screenshot' or not.
            let ssCheck = false;
            if (refundEmailTemplate.indexOf("Screenshot") !== -1) {
              ssCheck = true;
            }

            this.formData.ssCheck = ssCheck;

            // Assign refund remarks.
            this.formData.refundRemarks = data.refundRemarks;

            // Draft for attachment.
            const stagePriorities = [
              this.hrdcData.stagePriority.creditNoteGenerated,
              this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
              this.hrdcData.stagePriority.paymentAdviceFromHrdc,
              this.hrdcData.stagePriority.paymentAdviceFromClient,
              this.hrdcData.stagePriority.refundEmailOtherAttachments,
            ];

            const attachments = {};
            let otherRefundAttnCount = 0;

            for (const stageId of stagePriorities) {
              let filesArr = [];
              const attachment = data.ApplicationAttachment.filter(
                (item) => item.stageId == stageId
              );

              if (attachment.length > 0) {
                for (const atch of attachment) {
                  // Cater for other refund attachments.
                  if (
                    stageId ==
                    this.hrdcData.stagePriority.refundEmailOtherAttachments
                  ) {
                    if (!attachments[stageId]) {
                      attachments[stageId] = {};
                    }

                    attachments[stageId][otherRefundAttnCount] = {
                      fileUrl: atch.fileUrl,
                      fileName: atch.oriFileName,
                    };
                    otherRefundAttnCount++;
                  } else {
                    filesArr.push(atch.fileUrl);

                    attachments[stageId] = {
                      fileName: attachment.oriFileName,
                    };
                  }
                }

                attachments[stageId].fileUrl = filesArr;
              }
            }

            // Get refund email attachment.
            this.apiGetRefundAttachment.setParams({
              applicationUuid: this.$route.query._ausk,
              attachments,
            });

            this.apiGetRefundAttachment.setCallbackCompleted((response) => {
              try {
                const { status, attn } = response;
                if (status) {
                  for (const item of attn) {
                    // Create a Blob object from the data
                    const blob = new Blob([new Uint8Array(item.dataArray)]);

                    let fileName = null;
                    let file = null;

                    // Cater for other refund email attachment.
                    if (item.other == true) {
                      switch (item.type) {
                        case "refundEmailOtherAttachments":
                          file = new File([blob], item.fileName, {
                            type: `application/${item.extension}`,
                          });

                          this.formData.refundOtherAttachments.push(file);
                          break;
                      }
                      continue;
                    }

                    switch (Number(item.type)) {
                      case this.hrdcData.stagePriority
                        .autoGeneratedTaxpodInvoice:
                        fileName = "Client/taxPOD Invoice";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.autoGeneratedTaxpodInvoice.push(file);
                        break;

                      case this.hrdcData.stagePriority.creditNoteGenerated:
                        fileName = "Credit Note";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.creditNote.push(file);
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
                        fileName = "Payment Advice From HRDC";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromHrdc = file;
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromClient:
                        fileName = "Payment Advice From Client";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromClient = file;
                        break;
                    }
                  }

                  // Set fake template for credit note if is edit mode (edit mode cannot re-submit, unless rejected).
                  // this.formData.creditNote = {
                  //   name: "Credit Note",
                  // };

                  // Only load the email content, after all watch run.
                  this.formData.emailContent = refundEmailTemplate;
                }
              } catch (err) {
                console.log(err);
              }
            });

            this.apiGetRefundAttachment.fetch();
          }

          this.loadingDialog = !this.loadingDialog;
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      // New email refund submission, get related pic recipient details & taxpod subscription details.
      this.apiGetRefundEmailReceiver.setCallbackCompleted((response) => {
        const { data, status } = response;
        if (status) {
          const applicationDetails = response.application;

          // Assign company data.
          const companyDetails = response.company;
          this.formData.oldCompanyName = this.capitalizeFirstLetter(
            companyDetails.oldCompanyName
          );
          this.formData.oldSsmNo = companyDetails.oldSsmNo;
          this.formData.bankUuid = companyDetails.bankUuid;
          this.formData.oldBankAccount = companyDetails.oldBankAccount;

          const [subscriptionDetails] =
            applicationDetails.CompanyTaxpodSubscription; // [] use to get the first element instead of [0]
          const toEmailItems = data.toRecipient;
          const ccEmailItems = data.ccRecipient;

          let paymentReceivedDate = applicationDetails.ApplicationLog.find(
            (item) =>
              item.stageId ==
              this.hrdcData.stagePriority.paymentReceivedFromHrdc
          );

          if (paymentReceivedDate) {
            this.formData.paymentReceivedDate = moment(
              paymentReceivedDate.startDate,
              "YYYY-MM-DD HH:mm:ssZ"
            ).format("D MMMM YYYY");
          }

          // Assign Original company name filled by applicant.
          this.formData.oriCompanyName = applicationDetails.oriCompanyName;

          this.formData.oldBankAccountHolderName =
            companyDetails.oldBankAccountHolderName;

          // Email Subject.
          this.formData.emailSubject = `taxPOD HRDC Refund - ${this.formData.oriCompanyName.toUpperCase()}`;

          // Append to recipient.
          for (const toEmail of toEmailItems) {
            this.formData.toEmail.push(toEmail.email);
            this.formData.toEmailUuid.push(toEmail.uuid);
          }

          // Append cc recipient.
          for (const ccEmail of ccEmailItems) {
            this.formData.ccEmail.push(ccEmail.email);
            this.formData.ccEmailUuid.push(ccEmail.uuid);
          }

          // Assign price & subscription period details.
          this.formData.taxpodPrice = applicationDetails.totalPrice;

          // Check if pricing is unsual, display warning.
          if (
            !this.hrdcData.staticTaxpodPricing.includes(
              Number(applicationDetails.totalPrice)
            )
          ) {
            this.alertDanger.push("Detected Unusual Price, Please Take Note");
          }

          this.formData.manualInvoiceToHrdc =
            applicationDetails.manualInvoiceToHrdc;
          this.formData.subscriptionStartDate =
            subscriptionDetails.subscriptionStartDate;
          this.formData.subscriptionEndDate =
            subscriptionDetails.subscriptionEndDate;

          // Assign refund email company bank details.
          this.updateEmailContent();

          // Check if this is an rejected application.
          this.apiGetApplicationSingleDetail.setCallbackCompleted(
            (response) => {
              const { data } = response;

              // Define auto generated taxpod invoice & credit note attachments.
              let taxpodInvoiceFile = null;
              let taxpodInvoice = data.ApplicationAttachment.filter(
                (item) =>
                  item.stageId ==
                  this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice
              );

              if (taxpodInvoice.length > 0) {
                taxpodInvoiceFile = taxpodInvoice;
              }

              let creditNoteGenerated = data.ApplicationAttachment.filter(
                (item) =>
                  item.stageId ==
                  this.hrdcData.stagePriority.creditNoteGenerated
              );

              if (creditNoteGenerated.length > 0) {
                for (const cn of creditNoteGenerated) {
                  const blob = new Blob([new Uint8Array(cn.dataArray)]);
                  const parts = cn.fileUrl.split(".");
                  const extension = parts[parts.length - 1];
                  const cnFile = new File([blob], "Credit Note", {
                    type: `application/${extension}`,
                  });

                  this.formData.creditNote.push(cnFile);

                  // Assign this CN to preview button.
                  this.formData.previewCreditNote = cn.fileUrl;
                }
              } else {
                this.creditNoteValidationCheck = false;
              }

              this.formData.autoGeneratedTaxpodInvoiceNo =
                data.autoGeneratedTaxpodInvoiceNo;

              // Define related refund stages.
              const refundStage = [
                this.hrdcData.stagePriority.refundEmailSubmission,
                this.hrdcData.stagePriority.refundEmailApproval1,
                this.hrdcData.stagePriority.refundEmailApproval2,
              ];

              // Recipient Details.
              let toRecipient, ccRecipient;
              let refundEmail = data.ApplicationEmail.find((item) =>
                refundStage.includes(item.stageId)
              );

              if (refundEmail) {
                toRecipient = refundEmail.ApplicationEmailDetail.filter(
                  (item) => item.recipientType == "TO"
                ).map((item2) => item2.recipientEmail);
                ccRecipient = refundEmail.ApplicationEmailDetail.filter(
                  (item) => item.recipientType == "CC"
                ).map((item2) => item2.recipientEmail);

                // Email subject.
                this.formData.emailSubject = refundEmail.emailSubject;

                // Email Recipient.
                this.formData.toEmail = toRecipient;
                this.formData.ccEmail = ccRecipient;
              }

              // Client/taxPOD Invoice No.
              this.formData.autoGeneratedTaxpodInvoiceNo =
                data.autoGeneratedTaxpodInvoiceNo;

              // CN No.
              this.formData.creditNoteNo = data.creditNoteNo;

              // Payment Advice Type.
              if (data.paymentAdviceFromClientType) {
                this.formData.paymentAdviceType =
                  data.paymentAdviceFromClientType;
              }

              // Email Content
              let refundEmailTemplate = data.ApplicationEmail.find((item) =>
                refundStage.includes(item.stageId)
              );

              if (refundEmailTemplate) {
                refundEmailTemplate = refundEmailTemplate.emailContent;

                // Only load the email content, after all watch run.
                this.formData.emailContent = refundEmailTemplate;

                // Check is drafted refund email template got keyword 'screenshot' or not.
                let ssCheck = false;
                if (refundEmailTemplate.indexOf("Screenshot") !== -1) {
                  ssCheck = true;
                }

                this.formData.ssCheck = ssCheck;

                // Draft for attachment.
                const stagePriorities = [
                  this.hrdcData.stagePriority.creditNoteGenerated,
                  this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
                  this.hrdcData.stagePriority.paymentAdviceFromHrdc,
                  this.hrdcData.stagePriority.paymentAdviceFromClient,
                  this.hrdcData.stagePriority.refundEmailOtherAttachments,
                ];

                const attachments = {};
                let otherRefundAttnCount = 0;

                for (const stageId of stagePriorities) {
                  let filesArr = [];
                  const attachment = data.ApplicationAttachment.filter(
                    (item) => item.stageId == stageId
                  );

                  if (attachment.length > 0) {
                    for (const atch of attachment) {
                      // Cater for other refund attachments.
                      if (
                        stageId ==
                        this.hrdcData.stagePriority.refundEmailOtherAttachments
                      ) {
                        if (!attachments[stageId]) {
                          attachments[stageId] = {};
                        }

                        attachments[stageId][otherRefundAttnCount] = {
                          fileUrl: atch.fileUrl,
                          fileName: atch.oriFileName,
                        };
                        otherRefundAttnCount++;
                      } else {
                        filesArr.push(atch.fileUrl);

                        attachments[stageId] = {
                          fileName: attachment.oriFileName,
                        };
                      }
                    }

                    attachments[stageId].fileUrl = filesArr;
                  }
                }

                // Get refund email attachment.
                this.apiGetRefundAttachment.setParams({
                  applicationUuid: this.$route.query._ausk,
                  attachments,
                });

                this.apiGetRefundAttachment.setCallbackCompleted((response) => {
                  try {
                    const { status, attn } = response;
                    if (status) {
                      for (const item of attn) {
                        // Create a Blob object from the data
                        const blob = new Blob([new Uint8Array(item.dataArray)]);

                        let fileName = null;
                        let file = null;

                        // Cater for other refund email attachment.
                        if (item.other == true) {
                          switch (item.type) {
                            case "refundEmailOtherAttachments":
                              file = new File([blob], item.fileName, {
                                type: `application/${item.extension}`,
                              });

                              this.formData.refundOtherAttachments.push(file);
                              break;
                          }
                          continue;
                        }

                        switch (Number(item.type)) {
                          case this.hrdcData.stagePriority
                            .autoGeneratedTaxpodInvoice:
                            fileName = "Client/taxPOD Invoice";
                            // Create the File object
                            file = new File([blob], fileName, {
                              type: `application/${item.extension}`,
                            });

                            this.formData.autoGeneratedTaxpodInvoice.push(file);
                            break;

                          // case this.hrdcData.stagePriority.creditNoteGenerated:
                          //   fileName = "Credit Note";
                          //   // Create the File object
                          //   file = new File([blob], fileName, {
                          //     type: `application/${item.extension}`,
                          //   });

                          //   this.formData.creditNoteGenerated = file;
                          //   break;

                          case this.hrdcData.stagePriority
                            .paymentAdviceFromHrdc:
                            fileName = "Payment Advice From HRDC";
                            // Create the File object
                            file = new File([blob], fileName, {
                              type: `application/${item.extension}`,
                            });

                            this.formData.paymentAdviceFromHrdc = file;
                            break;

                          case this.hrdcData.stagePriority
                            .paymentAdviceFromClient:
                            fileName = "Payment Advice From Client";
                            // Create the File object
                            file = new File([blob], fileName, {
                              type: `application/${item.extension}`,
                            });

                            this.formData.paymentAdviceFromClient = file;
                            break;
                        }
                      }

                      // Only load the email content, after all watch run.
                      this.formData.emailContent = refundEmailTemplate;

                      this.loadingDialog = !this.loadingDialog;
                    }
                  } catch (err) {
                    console.log(err);
                  }
                });

                this.apiGetRefundAttachment.fetch();
              } else {
                // Construct params to retrieve taxpod invoice.
                const attachments = {};
                attachments[
                  this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice
                ] = {
                  fileUrl: taxpodInvoiceFile.map((item) => item.fileUrl),
                  fileName: "Client/taxPOD Invoice",
                };

                // Get the Client/taxPOD Invoice attachment here, if is brand new refund email draft.
                this.apiGetRefundAttachment.setParams({
                  applicationUuid: this.$route.query._ausk,
                  attachments,
                });

                this.apiGetRefundAttachment.setCallbackCompleted((response) => {
                  try {
                    const { status, attn } = response;
                    if (status) {
                      for (const item of attn) {
                        // Create a Blob object from the data
                        const blob = new Blob([new Uint8Array(item.dataArray)]);

                        let fileName = null;
                        let file = null;

                        switch (Number(item.type)) {
                          case this.hrdcData.stagePriority
                            .autoGeneratedTaxpodInvoice:
                            fileName = "Client/taxPOD Invoice";
                            // Create the File object
                            file = new File([blob], fileName, {
                              type: `application/${item.extension}`,
                            });

                            this.formData.autoGeneratedTaxpodInvoice.push(file);
                            break;
                        }
                      }
                    }

                    this.loadingDialog = !this.loadingDialog;
                  } catch (err) {
                    console.log(err);
                  }
                });
                this.apiGetRefundAttachment.fetch();
              }

              // Refund Remarks.
              this.formData.refundRemarks = data.refundRemarks;
            }
          );

          this.apiGetApplicationSingleDetail.fetch();
        }
      });

      this.apiGetRefundEmailReceiver.setParams({
        emailToSsoRole: ["hrdc-taxpod-refund-email-to-recipient"],
        emailToUserGroupRole: ["finance-ap-group", "finance-ar-group"],
        emailCcSsoRole: ["hrdc-taxpod-refund-email-cc-recipient"],
        emailCcUserGroupRole: ["learn-taxpod"],
        applicationUuid: this.$route.query._ausk,
      });
      this.apiGetRefundEmailReceiver.fetch();
      // this.loadingDialog = !this.loadingDialog;
    }
  },
  mounted() {},
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    //EOC
    modalRefundEmailSubmissionSubmit() {
      this.refundEmailSubmissionSubmitDialog =
        !this.refundEmailSubmissionSubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    addDropFile(e, key, fileType, matchExtType) {
      let file = e.dataTransfer.files[0];

      if (matchExtType) {
        if (!file.type.match(matchExtType)) {
          switch (matchExtType) {
            case "pdf.*":
              this.errorField[key] = "Please select pdf file format";
              break;
            case "image/*":
              this.errorField[key] = "Please select only image";
              break;
          }
          return;
        }
      }

      this.formData[key] = file;
      if (fileType) {
        this.scanPdf(e, key, fileType);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addDropMultipleFile(e, key, fileType, matchExtType) {
      let files = e.dataTransfer.files;

      for (const file of files) {
        if (matchExtType) {
          if (!file.type.match(matchExtType)) {
            switch (matchExtType) {
              case "pdf.*":
                this.errorField[key] = "Please select pdf file format";
                break;
              case "image/*":
                this.errorField[key] = "Please select only image";
                break;
            }
            return;
          }
        }

        this.formData[key].push(file);

        if (fileType) {
          this.scanPdf(e, key, fileType);
        }
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    scanPdf($event, fileType, displayFileInvoiceNo) {
      this.clearErrorMsg(fileType);
      this.clearErrorMsg(displayFileInvoiceNo);
      if ($event) {
        this.loadingDialog = !this.loadingDialog;
        const fd = new FormData();

        let counter = 0;
        for (const file of this.formData[fileType]) {
          fd.append(`${fileType}_${counter}`, file);
          counter++;
        }

        fd.append("fileType", fileType);
        fd.append("counter", counter);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            const { data } = response;
            if (response.status) {
              this.formData[displayFileInvoiceNo] = data;
            } else {
              this.formData[displayFileInvoiceNo] = null;
              this.$store.dispatch("showMessage", {
                message: "Unable to scan text!",
                messageType: "error",
                timeout: 2000,
              });
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      } else {
        // Empty the invoice no. if remove attachment.
        this.formData[displayFileInvoiceNo] = null;
      }
    },
    isValidURL(str) {
      try {
        new URL(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    previewOrDownloadAttachment(fileUrl) {
      this.loadingDialog = !this.loadingDialog;

      // Check if a valid url, directly open it.
      const isValidUrl = this.isValidURL(fileUrl);
      if (isValidUrl) {
        window.open(fileUrl, "_blank");
      } else {
        this.$axios
          .get(this.apiDownloadOrPreviewAttachment.url, {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              filePath: fileUrl,
              isImage: false,
            },
          })
          .then((response) => {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );

            window.open(blobUrl, "_blank");
          })
          .catch((error) => {
            if ((error.response.status == 404) | true) {
              this.$store.dispatch("showMessage", {
                message:
                  "Failed to Preview/Download attachment, kindly contact administrator",
                messageType: "error",
                timeout: 2000,
              });
            }
          });
      }

      this.loadingDialog = !this.loadingDialog;
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    updateEmailContent() {
      this.formData.emailContent = `
        <span>Hi AP & AR Team,</span><br /><br />
        <span>Grateful if you could help us process a
          <b> ${this.formattedPrice} </b>
          refund for ${this.formData.oriCompanyName.toUpperCase()} under YYC taxPOD.
        </span>
        <br />
        <br />
        <span>Client has bought YYC taxPOD for the period
          <b>${moment(this.formData.subscriptionStartDate, "YYYY-MM-DD").format(
            "D MMMM YYYY"
          )}</b>
            to
          <b>${moment(this.formData.subscriptionEndDate, "YYYY-MM-DD").format(
            "D MMMM YYYY"
          )}</b>
            with invoice
          <b>${
            this.formData.autoGeneratedTaxpodInvoiceNo == null
              ? "_________"
              : this.formData.autoGeneratedTaxpodInvoiceNo
          }</b>. After that, client decided to
          claim HRDC for YYC taxPOD. We have received the claim from HRDC on
          <b>${this.formData.paymentReceivedDate}</b>
            <b>(Invoice No: ${this.formData.manualInvoiceToHrdc})</b>.
        </span>
        <br />

        <span>
          Hence, we need to refund the HRDC claim to client. We have obtained
          refund approval from Chau.
        </span>
        <br />
        <span>Attached please find the following documents:</span><br /><br />

        <span>1.
          <b>YYC taxPOD Invoice - ${
            this.formData.autoGeneratedTaxpodInvoiceNo == null
              ? "_________"
              : this.formData.autoGeneratedTaxpodInvoiceNo
          }</b></span><br /><br
        />

        <span>2.
        <b>Invoice billed to HRDC -
          ${this.formData.manualInvoiceToHrdc}</b></span><br /><br />

        <span>3. <b>Payment Advice from Client - ${
          this.formData.paymentAdviceType
        }</b></span><br /><br />

        <span>4.
        <b>Payment Advice from HRDC -
          ${this.formData.manualInvoiceToHrdc}</b></span><br /><br />

        <span>5. <b>Credit Note - ${
          this.formData.creditNoteNo == null
            ? "__________"
            : this.formData.creditNoteNo
        }</b></span><br /><br />

        <span>6.
          <b>${this.numberSixItemWord}</b></span><br
        /><br />

        <span>7. <b>Refund Bank Account Details:</b></span><br />

        <span>
          Company Name: ${this.formData.oldBankAccountHolderName.toUpperCase()}<br>Company Registration No.: ${
        this.formData.oldSsmNo
      }<br>Bank: ${this.formData.bankUuid}<br>Bank Account Number: ${
        this.formData.oldBankAccount
      }
        </span>
      `;
    },
    changeAttachSs() {
      if (this.formData.ssCheck) {
        this.formData.ssCheckMsg =
          "Include Screenshot Refund Bank Account Details in Refund Email";

        this.numberSixItemWord =
          "Screenshot of Refund Bank Account Details Provided by Client";
      } else {
        this.formData.ssCheckMsg =
          "Exclude Screenshot Refund Bank Account Details Refund Email";

        this.numberSixItemWord = "Authorized Letter";
      }
    },
    checkAttachment(e, field) {
      if (e) {
        this.clearErrorMsg(field);
      }
    },
    getFileExt(fileType) {
      if (fileType) {
        // Get the file extension type.
        const fileSplit = fileType.split("/");
        return fileSplit[1];
      }

      return fileType;
    },
    async submit() {
      const changeFileExtensions = ["paymentAdviceFromClient"];

      for (const file of changeFileExtensions) {
        if (this.formData[file]) {
          let oriFileExt = await this.getOriginalAttachmentFileExtension(
            this.formData[file]
          );

          this.formData[file] = new File(
            [this.formData[file]],
            this.formData[file].name,
            {
              type: oriFileExt,
            }
          );
        }
      }

      this.loadingDialog = !this.loadingDialog;

      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("totalPrice", this.formData.taxpodPrice);
      fd.append("subscriptionStartDate", this.formData.subscriptionStartDate);
      fd.append("subscriptionEndDate", this.formData.subscriptionEndDate);
      fd.append("emailSubject", this.formData.emailSubject);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("toEmailUuid", this.formData.toEmailUuid);
      fd.append("ccEmailUuid", this.formData.ccEmailUuid);

      // Loop taxPOD invoice.
      let taxpodInvoiceCounter = 0;
      for (const taxpodInvoice of this.formData.autoGeneratedTaxpodInvoice) {
        fd.append(
          `autoGeneratedTaxpodInvoice_${taxpodInvoiceCounter}`,
          taxpodInvoice
        );
        taxpodInvoiceCounter++;
      }
      fd.append("taxpodInvoiceCounter", taxpodInvoiceCounter);

      fd.append(
        "autoGeneratedTaxpodInvoice",
        this.formData.autoGeneratedTaxpodInvoice
      );

      fd.append(
        "autoGeneratedTaxpodInvoiceNo",
        this.formData.autoGeneratedTaxpodInvoiceNo
      );

      let cnCounter = 0;
      for (const cn of this.formData.creditNote) {
        fd.append(`creditNote_${cnCounter}`, cn);
        cnCounter++;
      }

      fd.append("cnCounter", cnCounter);
      fd.append("creditNoteNo", this.formData.creditNoteNo);
      fd.append(
        "refundEmailSubmissionDate",
        this.formData.refundEmailSubmissionDate
      );
      fd.append(
        "paymentAdviceFromClient",
        this.formData.paymentAdviceFromClient
      );
      fd.append("paymentAdviceFromHrdc", this.formData.paymentAdviceFromHrdc);
      fd.append("paymentAdviceType", this.formData.paymentAdviceType);
      fd.append("referById", this.auth.uuid);
      fd.append("referByName", this.auth.name);
      fd.append("referByEmail", this.auth.email);
      fd.append(
        "referByMobile",
        this.auth.mobile ?? this.hrdcData.yycMobileHotline
      );
      fd.append("editMode", this.editMode);
      fd.append("refundRemarks", this.formData.refundRemarks);
      fd.append("emailContent", this.formData.emailContent);
      fd.append("ssCheck", this.formData.ssCheck);
      fd.append("creditNoteValidationCheck", this.creditNoteValidationCheck);

      // Assign multiple attachments to Form Data.
      for (let i = 0; i < this.formData.refundOtherAttachments.length; i++) {
        fd.append(
          `refundOtherAttachments_${i}`,
          this.formData.refundOtherAttachments[i]
        );
      }

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>

<style scroped>
.v-messages__message {
  color: red;
}
</style>
